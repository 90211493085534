@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Josefin+Slab:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


* {
  font-family: 'Josefin Sans', sans-serif;
  color: #FFE7CF;
  background-color: #3A505F;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, li {
  font-family: 'Josefin Slab', serif;
  font-weight: bold;
  font-size: 18px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover {
  color: #fdec9a;
}

/* .sidebar-socials {
  width: 100%;
  max-width: 40px;
  margin-left: -8px;
  margin: 0 auto 10px;
  position: absolute;
  bottom: 5em;
  z-index: 10;
  background-color: #3A505F;
}

.sidebar-email {
  width: 100%;
  max-width: 40px;
  margin-right: -8px;
  margin: 0 auto 10px;
  position: absolute;
  bottom: 5em;
  z-index: 10;
  background-color: #3A505F;
} */

